import { useAppStore } from "~/stores/AppStore";

export const useAppUpdate = () => {
  const appStore = useAppStore();
  let checkTimer: any = null;
  const CHECK_INTERVAL = 1000 * 60 * 60; // 1時間ごとにチェック

  const onMounted = () => {
    checkTimer = setInterval(appStore.checkUpdate, CHECK_INTERVAL);
  };

  const onUnmounted = () => {
    if (checkTimer) {
      clearInterval(checkTimer);
      checkTimer = null;
    }
  };

  return {
    onMounted,
    onUnmounted,
  };
};
