import axios from "axios";
import { defineStore } from "pinia";

export const useAppStore = defineStore("AppStore", () => {
  const appVersion = ref<string | null>(null);
  const needsUpdate = ref(false);

  const init = async () => {
    appVersion.value = await getCurrentVersion();
    needsUpdate.value = false;
  };

  const checkUpdate = async () => {
    const version = await getCurrentVersion();
    if (appVersion.value !== version) {
      needsUpdate.value = true;
    }
  };

  const executeUpdate = () => {
    reloadNuxtApp();
  };

  const getCurrentVersion = async () => {
    const res = await axios.get(`/appenv.json`);
    if (res.status !== 200) {
      return null;
    }
    return res.data.appVersion;
  };

  return {
    appVersion,
    needsUpdate,
    init,
    checkUpdate,
    executeUpdate,
  };
});
