// service workerからlocalStorageにアクセスすることはできないので、localforage(indexedDBのラッパー)を利用する。
import localforage from "localforage";

// localforageの初期化
const initLocalForage = () => {
  localforage.config({
    driver: localforage.INDEXEDDB,
    name: "fincs-db",
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    version: 1.0,
    storeName: "keyvaluepairs",
    description: "",
  });
};

// 未読数をバッジ表示する
export const addBadgeCount = async () => {
  try {
    initLocalForage();
    // 保存している未読数を取得し、カウントアップして保存する
    let unreadCount = await localforage.getItem("unreadWebPushCount");
    unreadCount = unreadCount ? parseInt(unreadCount) + 1 : 1;
    await localforage.setItem("unreadWebPushCount", unreadCount.toString());

    // 未読数をバッジ表示する
    if (navigator.setAppBadge && navigator.clearAppBadge) {
      if (unreadCount && unreadCount > 0) {
        navigator.setAppBadge(unreadCount);
      } else {
        navigator.clearAppBadge();
      }
    }
  } catch (e) {
    console.error(e);
  }
};

// 未読数バッジをクリアする
export const clearBadgeCount = async () => {
  try {
    initLocalForage();
    // 未読数を0にして保存する
    await localforage.setItem("unreadWebPushCount", "0");
    // バッジをクリアする
    if (navigator.clearAppBadge) {
      navigator.clearAppBadge();
    }
  } catch (e) {
    console.error(e);
  }
};
